import React, { useEffect, useState } from "react";
import Feedback from "../../Feedback/Feedback";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import "../PagesStyles/HomePage.css";
import BestDesign from "./BestDesign";
import Caraousal from "./Caraousal";
import Categories from "./Categories";
import ContactUs from "./ContactUs";
import OtherCategories from "./OtherCategories";
import TrendingJewellery from "./TrendingJewellery";
import WhyUs from "./WhyUs";
import upLeftCorner from "../../Images/upLeftCorner.png";
import upRightCorner from "../../Images/upRightCorner.png";
import bestGift from "../../Images/bestGift.jpg";
import { Link, useNavigate } from "react-router-dom";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import { a16 } from "../../Api/RootApiPath";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts } from "../../../redux/action/Actions";
import UspPoints from "../../UspPoints/UspPoints";
import BeMemberPopup from "./BeMemberPopup";
import { AiOutlineClose } from "react-icons/ai";
import MobileCarousal from "./MobileCarousal";
import SpecialOccasion from "./SpecialOccasion";

export default function HomePage() {
  const [mainContainerActive, setMainContainerActive] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 1350 && window.scrollY < 3000) {
      setMainContainerActive(true);
    } else {
      setMainContainerActive(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const dispatch = useDispatch();
  const all = useSelector((state) => state);
  const userDetails = all.reducer4[0];

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1200 });
  }, []);
  useEffect(() => {
    fbq("track", "ViewContent");
  }, []);

  const [popupVisible, setPopupVisible] = useState(false);
  console.log(userDetails);
  useEffect(() => {
    if (userDetails == undefined) {
      const timer = setTimeout(() => {
        setPopupVisible(true);
        // Add this line
      }, 3000);

      return () => {
        clearTimeout(timer);
        setPopupVisible(false);
        document.body.classList.remove("body-no-scroll"); // Add this line
      };
    }
  }, [userDetails]);

  const navigate = useNavigate();
  const [buttonPopup, setButtonPopup] = useState(false);
  const buttonHovered = (value) => {
    if (value == true) {
      setButtonPopup(true);
      // alert("true");
    } else {
      setButtonPopup(false);
      // alert("false");
    }
  };

  return (
    <div
      style={{ overflowX: "hidden" }}
      className={mainContainerActive ? "mainContainerActive" : "mainContainer"}
    >
      <div className="headingBox">
        <Heading />
      </div>
      {/* <div className="feedback">
        <Feedback />
      </div> */}

      <div className="homeCaraousalDesktop">
        <Caraousal />
      </div>
      <div className="homeCaraousalMobile">
        <MobileCarousal />
      </div>

      {popupVisible && (
        <div className="overlay">
          <div className={!buttonPopup ? "popup" : "popup popBoxButtonHovered"}>
            <div className="popup-content">
              <AiOutlineClose
                size={"2rem"}
                className="homePopupCloseBtn"
                onClick={() => {
                  setPopupVisible(false),
                    document.body.classList.remove("body-no-scroll");
                }}
              />
              <BeMemberPopup buttonHovered={buttonHovered} />
            </div>
          </div>
        </div>
      )}

      <div className="homePageAllCategoriesBox" style={{ marginTop: "1rem" }}>
        {/* <h1
          data-aos="zoom-out"
          className="homepageFreshArrivalsHeading"
          onClick={() => navigate("/specialoccasion/akshaytritiya")}
        >
          AKSHAY TRITIYA SPECIAL
          <div className="trendingBorder" data-aos="fade-up"></div>
          <div
            className="trendingBorder trendingBorder2 "
            data-aos="fade-down"
          ></div>
        </h1> */}
        {/* <SpecialOccasion /> */}
        <Categories />
        <OtherCategories />
        <h1
          data-aos="zoom-out"
          className="homepageFreshArrivalsHeading"
          onClick={() => navigate("/edition/Fresh Arrivals")}
        >
          {/* Divine Dhanteras Treasures! */}
          {/* Trending Jewellery! */}
          Fresh Arrivals
          <div className="trendingBorder" data-aos="fade-up"></div>
          <div
            className="trendingBorder trendingBorder2 "
            data-aos="fade-down"
          ></div>
        </h1>
        {/* <div class="comingSoon">
          <span class="toolTipText">Coming Soon</span>
          Hover me
        </div> */}

        <TrendingJewellery />

        {/* <h1 data-aos="fade-left">Looking for the Best Design?</h1> */}
        <h1
          data-aos="zoom-out"
          className="homepageFreshArrivalsHeading"
          onClick={() => navigate("/limitededition/Limited Edition")}
        >
          {/* Divine Dhanteras Treasures! */}
          {/* Trending Jewellery! */}
          Limited Edition
          <div className="trendingBorder" data-aos="fade-up"></div>
          <div
            className="trendingBorder trendingBorder2 "
            data-aos="fade-down"
          ></div>
        </h1>
        {/* <img
            src={upLeftCorner}
            className="bestDesignsCorner leftCorner"
            data-aos="fade-right" 
          ></img>
          <img
            src={upRightCorner}
            className="bestDesignsCorner rightCorner" 
            data-aos="fade-left"
          ></img> */}

        <BestDesign />
        {/* <Link to="/shoppage"> */}

        {/* </Link> */}

        {/* <UspPoints /> */}
        <WhyUs />
        <h1
          // style={{
          //   fontFamily: "Red Hat Display, sans-serif",
          //   // marginTop: "10rem",
          //   color: "rgba(26, 76, 44, 255)",
          //   // fontSize: "40px",
          //   // width: "20%",
          //   textAlign: "center",
          // }}
          data-aos="zoom-out"
        >
          Need Help?{" "}
          <span style={{ fontWeight: 100 }} data-aos="zoom-out">
            Contact Us.
          </span>
          <div className="trendingBorder" data-aos="fade-up"></div>
          <div
            className="trendingBorder trendingBorder2 "
            data-aos="fade-down"
          ></div>
        </h1>
        <div
          className="contactUsHomeBox"
          // data-aos="fade-in"
          // data-aos-offset="200"
          // data-aos-duration="700"
          // data-aos-easing="ease-in-sine"
        >
          <ContactUs />
        </div>

        <Footer />
      </div>
    </div>
  );
}
