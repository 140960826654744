import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { ColorRing } from "react-loader-spinner";
import { a16, s3 } from "../../Api/RootApiPath";
import { MdSort } from "react-icons/md";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import AkshayTritiyaBanner from "../../Images/CategoryPageBanners/akshayTritiyaBanner.jpg";
import AkshayTritiyaBannerMobile from "../../Images/CategoryPageBanners/akshayTritiyaBannerMobile.jpg";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

export default function AkshayTritiyaPage() {
  const { edition } = useParams();
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsNormalCoin, setFilteredProductsNormalCoin] = useState(
    []
  );
  const [filteredProductsGiftCoin, setFilteredProductsGiftCoin] = useState([]);
  const [normalCoins, setNormalCoins] = useState([]);
  const [giftCoins, setGiftCoins] = useState([]);
  const [silverCoins, setSilverCoins] = useState([]);
  const [showGoldCoins, setShowGoldCoins] = useState(true);
  const [showSilverCoins, setShowSilverCoins] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProductsData();
  }, [edition]);

  const fetchAllProductsData = async () => {
    try {
      const data = await fetch(a16);
      const response = await data.json();

      if (response.status === "No Data available") {
        setAllProducts("NoData");
        setLoading(false);
      } else {
        // Separate different types of Gold coins
        const silverCoins = response.data.filter(
          (product) =>
            product.collection.toLowerCase().includes("coin") &&
            product.category_Name.toLowerCase().includes("silver")
        );
        console.log(silverCoins, "Silver Coins");

        const silverCoinsSorted = silverCoins.sort((a, b) => a.netWt - b.netWt);
        console.log(silverCoinsSorted, "silver coins by weight");

        const pureGoldCoins = response.data.filter((product) =>
          product.itemType.toLowerCase().includes("pure gold coin")
        );
        console.log(pureGoldCoins, "pure gold coin");

        const pureGoldCoins995 = pureGoldCoins.filter((product) =>
          product.product_Name.includes("(995)")
        );
        console.log(pureGoldCoins995, "pure gold 995 coin");
        const pureGoldCoins995Sorted = pureGoldCoins995.sort(
          (a, b) => a.netWt - b.netWt
        );
        console.log(pureGoldCoins995, "pure gold 995 coin by weight");

        const giftCoins = response.data.filter(
          (product) =>
            product.itemType.toLowerCase().includes("gold coin") &&
            !product.itemType.toLowerCase().includes("pure")
        );
        const otherGoldGiftCoins = response.data.filter(
          (product) =>
            product.product_Name.toLowerCase().includes("coin") &&
            product.category_Name.toLowerCase().includes("gold") &&
            !pureGoldCoins.includes(product) && // It's not a pure gold coin
            !giftCoins.includes(product) // It's not already categorized as a gift coin
        );

        const pureGoldCoins999 = giftCoins.filter(
          (product) =>
            !product.product_Name.toLowerCase().includes("lord") &&
            !product.product_Name.toLowerCase().includes("goddess") &&
            !product.product_Name.toLowerCase().includes("rose") &&
            !product.product_Name.toLowerCase().includes("kalpvruksh")
        );
        console.log(pureGoldCoins999, "pure gold 999 coin");
        const pureGoldCoins999Sorted = pureGoldCoins999.sort(
          (a, b) => a.netWt - b.netWt
        );
        console.log(pureGoldCoins999, "pure gold 995 coin by weight");

        const giftCoinsNotPure = giftCoins.filter(
          (product) =>
            product.product_Name.toLowerCase().includes("lord") ||
            product.product_Name.toLowerCase().includes("goddess") ||
            product.product_Name.toLowerCase().includes("rose") ||
            product.product_Name.toLowerCase().includes("kalpvruksh")
        );

        const combinedGiftCoins = [...giftCoinsNotPure, ...otherGoldGiftCoins];
        // const giftCoinSorted = giftCoinsNotPure.sort(
        const giftCoinSorted = combinedGiftCoins.sort(
          (a, b) => a.netWt - b.netWt
        );
        const otherGoldGiftCoinSorted = otherGoldGiftCoins.sort(
          (a, b) => a.netWt - b.netWt
        );
        console.log(pureGoldCoins999, "lord  coin by weight");

        // Combine other products and anklet products (anklet products moved to the end)
        const combinedProducts = [
          ...pureGoldCoins995Sorted,
          ...pureGoldCoins999Sorted,

          ...giftCoinSorted,
        ];

        setAllProducts(combinedProducts);
        setNormalCoins([...pureGoldCoins995Sorted, ...pureGoldCoins999Sorted]);
        // setGiftCoins([...giftCoinSorted, ...otherGoldGiftCoinSorted]);
        setGiftCoins([...giftCoinSorted]);
        setSilverCoins(silverCoins);
        setLoading(false);
      }
      // setAllProducts(data.data.reverse());
      // setLoading(false);
    } catch (error) {
      console.log("Error fetching products data: ", error);
    }
  };
  console.log(allProducts, "allProducts");
  console.log(normalCoins, "normalCoins");
  console.log(giftCoins, "giftCoins");
  const filteredProductsData = allProducts;
  const filteredProductsNormalCoinsData = normalCoins;
  const filteredProductsGiftCoinsData = giftCoins;
  const filteredProductsSilverCoinsData = silverCoins;
  // const filteredProductsData = allProducts.filter((product) => {
  //   const productName = (product.edition || "").toLowerCase();
  //   const editions = (edition || "").toLowerCase().split(" ");
  //   return editions.every((word) => productName.includes(word));
  // });

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    id,
    phuid
  ) => {
    // Parse input values to float and handle NaN cases
    netWt = parseFloat(netWt) || 0;
    making_per_gram = parseFloat(making_per_gram) || 0;
    making_Percentage = parseFloat(making_Percentage) || 0;
    making_Fixed_Amt = parseFloat(making_Fixed_Amt) || 0;
    making_Fixed_Wastage = parseFloat(making_Fixed_Wastage) || 0;
    stoneAmount = parseFloat(stoneAmount) || 0;
    mrp = parseFloat(mrp) || 0;
    offerPrice = parseFloat(offerPrice) || 0;
    purityRate = parseFloat(purityRate) || 0;

    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;

    let grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let finalPrice = 0;

    // if (offerPrice !== 0) {
    //   finalPrice = offerPrice;
    // } else
    if (mrp !== 0) {
      finalPrice = mrp;
    }
    if (
      // offerPrice === 0 &&
      mrp === 0
    ) {
      finalPrice = grossTotalRate; // Set final price to grossTotalRate
    }

    // let GSTAdded = GST * grossTotalRate;
    // finalPrice += GSTAdded;

    return finalPrice.toFixed(0);
  };

  const updatedData0 = filteredProductsData.map((product) => ({
    ...product,
    purityRate: parseFloat(product.tblPurity.todaysRate),
  }));
  const updatedDataNormalCoins0 = filteredProductsNormalCoinsData.map(
    (product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    })
  );
  const updatedDataGiftCoins0 = filteredProductsGiftCoinsData.map(
    (product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    })
  );

  const updatedData = updatedData0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));
  const updatedDataNormalCoins = updatedDataNormalCoins0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));
  const updatedDataGiftCoins = updatedDataGiftCoins0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));
  const allProductsAvailable = () => {
    return filteredProducts.map((product) => (
      <div
        key={product.id}
        style={{ borderRadius: "10px" }}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        {/* <div className="homePageSpecialOccasionContainerImageBox"> */}
        <img
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        {/* </div> */}
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{product.product_Name}</h3>
          </div>
          {/* <div className="shoppageProductDescriptionBoxDetails">
            <p>{`${product.description.substr(0, 75)} ...`}</p>
          </div> */}
          <div className="shoppageProductDescriptionBoxDetails">
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <strong>
                <h3 style={{ color: "green", fontWeight: "bold" }}>
                  ₹{product.sellPrice}
                </h3>
              </strong>
            )}
          </div>
        </div>
      </div>
    ));
  };
  const allProductsAvailableNormalCoins = () => {
    return filteredProductsNormalCoin.map((product) => (
      <div
        key={product.id}
        style={{
          borderRadius: "10px",
          height: "auto",
          backgroundColor: "white",
        }}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        {/* <div className="homePageSpecialOccasionContainerImageBox"> */}
        <img
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        {/* </div> */}
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{product.product_Name}</h3>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <p
              style={{ color: "rgba(0,0,0,0.4)" }}
            >{`${product.description.substr(0, 75)} ...`}</p>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <div className="specialOccasionPageProductLabelOuterBox">
              <div className="specialOccasionPageProductLabelInnerBox">
                <p>{product.netWt} Gram</p>
              </div>
            </div>
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.sellPrice}
                  </h3>
                </strong>
              </>
            )}
          </div>
        </div>
      </div>
    ));
  };
  const allProductsAvailableGiftCoins = () => {
    return filteredProductsGiftCoin.map((product) => (
      <div
        key={product.id}
        className="container"
        style={{
          borderRadius: "10px",
          height: "auto",
          backgroundColor: "white",
        }}
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        {/* <div className="homePageSpecialOccasionContainerImageBox"> */}
        <img
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        {/* </div> */}
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{`${product.product_Name.substr(0, 50)} ...`}</h3>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <p
              style={{ color: "rgba(0,0,0,0.4)" }}
            >{`${product.description.substr(0, 75)} ...`}</p>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <div className="specialOccasionPageProductLabelOuterBox">
              <div className="specialOccasionPageProductLabelInnerBox">
                <p>{product.netWt} Gram</p>
              </div>
            </div>
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <strong>
                <h3 style={{ color: "green", fontWeight: "bold" }}>
                  ₹{product.sellPrice}
                </h3>
              </strong>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const allProductsAvailableSilverCoins = () => {
    console.log(silverCoins, "allProductsAvailableSilverCoins");
    // Function to render silver coin products
    return silverCoins.map((product) => (
      <div
        key={product.id}
        style={{
          borderRadius: "10px",
          height: "auto",
          backgroundColor: "white",
        }}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        {/* <div className="homePageSpecialOccasionContainerImageBox"> */}
        <img
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        {/* </div> */}
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{`${product.product_Name.substr(0, 50)} ...`}</h3>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <p
              style={{ color: "rgba(0,0,0,0.4)" }}
            >{`${product.description.substr(0, 75)} ...`}</p>
          </div>
          <div className="shoppageProductDescriptionBoxDetails">
            <div className="specialOccasionPageProductLabelOuterBox">
              <div className="specialOccasionPageProductLabelInnerBox">
                <p>{product.netWt} Gram</p>
              </div>
            </div>
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <strong>
                <h3 style={{ color: "green", fontWeight: "bold" }}>
                  ₹{product.sellPrice}
                </h3>
              </strong>
            )}
          </div>
        </div>
      </div>
    ));
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  useEffect(() => {
    const updatedData0 = filteredProductsData.map((product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    }));

    const updatedData = updatedData0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));
    const updatedDataNormalCoins0 = filteredProductsNormalCoinsData.map(
      (product) => ({
        ...product,
        purityRate: parseFloat(product.tblPurity.todaysRate),
      })
    );

    const updatedDataNormalCoins = updatedDataNormalCoins0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));
    const updatedDataGiftCoins0 = filteredProductsGiftCoinsData.map(
      (product) => ({
        ...product,
        purityRate: parseFloat(product.tblPurity.todaysRate),
      })
    );

    const updatedDataGiftCoins = updatedDataGiftCoins0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));
    const updatedDataSilverCoins0 = filteredProductsSilverCoinsData.map(
      (product) => ({
        ...product,
        purityRate: parseFloat(product.tblPurity.todaysRate),
      })
    );

    const updatedSilverCoins = updatedDataSilverCoins0.map((product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));

    let tempFilteredProducts = updatedData;
    let tempFilteredProductsNormalCoins = updatedDataNormalCoins;
    let tempFilteredProductsGiftCoins = updatedDataGiftCoins;
    let tempFilteredProductsSilverCoins = updatedSilverCoins;

    setFilteredProducts(tempFilteredProducts);
    setFilteredProductsNormalCoin(tempFilteredProductsNormalCoins);
    setFilteredProductsGiftCoin(tempFilteredProductsGiftCoins);
    setSilverCoins(tempFilteredProductsSilverCoins);
    window.scrollTo(0, 0);
  }, [allProducts]);

  return (
    <div style={{ backgroundColor: "#fee9d4" }}>
      <div className="headingBox">
        <Heading />
      </div>
      {loading ? (
        // <div style={{ height: "150px", margin: "100px 0" }}>
        //   <Hearts width={200} color="#4fa94d" />
        // </div>
        <div
          style={{
            height: "150px",
            margin: "100px 0",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fee9d4",
          }}
        >
          <ColorRing
            height="100"
            width="100"
            // color="#4fa94d"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        // DesktopFilters()
        // <h1>Hi</h1>
        <div>
          <div className="desktopAllProductsBannerBox">
            <img src={AkshayTritiyaBanner} />
          </div>
          <div className="desktopAllProductsBannerBoxMobile">
            <img src={AkshayTritiyaBannerMobile} />
          </div>
          <div className="limitedButton-container">
            <button
              id="limitedButtonWomen"
              className={
                showGoldCoins
                  ? "limitedButton limitedButtonActive"
                  : "limitedButton"
              }
              onClick={() => {
                setShowGoldCoins(true);
                setShowSilverCoins(false);
              }}
            >
              Gold
            </button>
            <button
              id="limitedButtonMen"
              className={
                showSilverCoins
                  ? "limitedButton limitedButtonActive"
                  : "limitedButton"
              }
              onClick={() => {
                setShowSilverCoins(true);
                setShowGoldCoins(false);
              }}
            >
              Silver
            </button>
          </div>

          {showGoldCoins && (
            <>
              <h3 className="specialOccasionPageHeading">GOLD COINS</h3>
              <div className="shopPage">
                {allProductsAvailableNormalCoins()}
              </div>
              <h3 className="specialOccasionPageHeading">
                GOLD 999 GIFT COINS
              </h3>
              <div className="shopPage">{allProductsAvailableGiftCoins()}</div>
            </>
          )}
          {showSilverCoins && (
            <>
              <h3 className="specialOccasionPageHeading">SILVER COINS</h3>
              <div className="shopPage">
                {allProductsAvailableSilverCoins()}
              </div>
            </>
          )}
          {/* <h3 className="specialOccasionPageHeading">GOLD COINS</h3>
          <div className="shopPage">{allProductsAvailableNormalCoins()}</div>
          <h3 className="specialOccasionPageHeading">GOLD 999 GIFT COINS</h3>
          <div className="shopPage">{allProductsAvailableGiftCoins()}</div>
          <h3 className="specialOccasionPageHeading">SILVER COINS</h3>
          <div className="shopPage">{allProductsAvailableSilverCoins()}</div> */}
        </div>
      )}

      {/* {!loading || mobileFilterBox ? MobileFilters() : null}
      {!loading || mobileSortByBox ? MobileSortBy() : null}

      <div className="shoppageMobileFiltersMainBox">
        <div
          className="shoppageMobileFiltersLeftBox"
          onClick={() => handleOpenMobileFilterBox()}
        >
          <FiFilter />
          <p>Filter</p>
        </div>
        <div
          className="shoppageMobileFiltersRightBox"
          onClick={() => handleOpenMobileSortByBox()}
        >
          <MdSort />
          <p>Sort</p>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
