import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Keyboard, Pagination, Navigation, EffectFade } from "swiper";
import img1 from "../../Images/MobileCarousalImages/MobileCarousal1.jpg";
import img2 from "../../Images/MobileCarousalImages/MobileCarousal2.jpg";
import img3 from "../../Images/MobileCarousalImages/MobileCarousal3.jpg";
import img4 from "../../Images/MobileCarousalImages/MobileCarousal4.jpg";
import img5 from "../../Images/MobileCarousalImages/MobileCarousal5.jpg";
import img6 from "../../Images/MobileCarousalImages/MobileCarousal6.png";

// import img5 from "../../Images/Caraousal_Images/Trending5.jpg";

import "swiper/css";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import { useNavigate } from "react-router-dom";
export default function MobileCarousal() {
  const navigate = useNavigate();
  return (
    // <div className="caraousalImagesBox1">
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      effect={"fade"}
      speed={1300}
      keyboard={{
        enabled: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[EffectFade, Autoplay, Pagination, Navigation, Keyboard]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img1}
          alt={"Category name"}
          onClick={() => {
            // navigate("/specialoccasion/Gudi Padwa Special");
            // navigate("/specialoccasion/akshaytritiya");
            navigate("/gender/female/mangalsutra/silver");
          }}
        />
      </SwiperSlide>
      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img2}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/limitededition/Limited Edition");
          }}
        />
      </SwiperSlide> */}
      <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img3}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            // navigate("/itemType/Ganpati%20Ornaments/silver");
            navigate(
              `/itemType/Ganpati%20Ornaments/silver`
              // `/gender/male/${encodeURIComponent("Chain / Bracelet")}/silver`
            );
          }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img4}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            // navigate("/gender/female/chain/gold");
            // navigate("/oxidizedproducts");
            navigate(`/itemType/Ganpati%20Ornaments/silver`)
          }}
        />
      </SwiperSlide>
      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img5}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/gender/female/chain/silver");
          }}
        />
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img6}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/shoppage");
          }}
        />
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img2}
          alt={"Category name"}
          onClick={() => {
            navigate("/edition/Starlight Edit");
          }}
        />
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer", height: "auto" }}
          className="caraousalImages"
          src={img3}
          alt={"Category name"}
          onClick={() => {
            navigate("metal/gold/chain");
          }}
        />
      </SwiperSlide> */}
    </Swiper>
    // </div>
  );
}
